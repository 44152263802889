import { useEffect, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import classNames from "classnames";
import { useForm, Controller } from "react-hook-form";
import Icon from "../components/icons";
import CalendarSearch from "../components/calendar-search";
import Header from "../components/header";
import Breadcrumbs from "../components/breadcrumbs";
import EventCard from "../components/event-card";
import CustomSelect from "../components/custom-select";
import {
  FESTIVAL_DAY,
  DISCIPLINE,
  TIPO_EVENTO,
  ID_CONFERENZA,
  ID_SPETTACOLO,
  ID_EVENTO_SPECIALE,
} from "../utils/constants";
import { placeholders } from "../utils/admin";
import { useRelatori, useLuoghi, usePage } from "../hooks/useQueryCustom";
import scaricaProgramma from "../assets/scarica-programma.jpg";
import PageContent from "../components/page-content";
import Seo from "../components/seo";
import { useNavigazione } from "../context/navigazione";

const getPageFind = () => import("../../pagefind/pagefind.js");

const Programma = ({ page, breadcrumbs }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { programma: pdf } = useNavigazione();
  const [results, setResults] = useState(null);

  const searchPF = async (
    query,
    filter = { sezione_fe: "1" },
    sort = { title: "asc" },
    start = 0
  ) => {
    const nstart = Number(start);
    const pagefind = await getPageFind();
    await pagefind.filters();
    const search = await pagefind.search(query, {
      filters: {
        ...filter,
      },
      sort: {
        ...sort,
      },
    });
    const searchresults = await Promise.all(
      search.results.slice(nstart, nstart + 12).map((r) => r.data())
    );

    const meta = searchresults.map((i) => i.meta);
    const npages = Math.ceil(Number(search.results.length) / 12);
    const current = (nstart + 12) / 12 - 1;
    const pgresult = {
      nrows: search.results.length,
      npages: Array(npages).fill(""),
      current: current,
      rows: meta,
    };
    setResults(pgresult);
  };

  const [advanceSearch, setAdvanceSearch] = useState(
    searchParams.get("idrelatore_r") ||
      searchParams.get("d_r") ||
      searchParams.get("t_r")
  );
  const resultSection = useRef();
  const emptyValue = {
    d_r: "",
    dt_r: "",
    g_r: "",
    idrelatore_r: "",
    ml_r: "",
    t_r: "",
    order_r: "titolo",
  };

  const { data: relatori } = useRelatori();
  const { data: luoghi } = useLuoghi();

  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    setValue,
    // formState: { errors, isValid, isSubmitted, isSubmitSuccessful },
  } = useForm({
    defaultValues: async () => {
      let defaults = Object.fromEntries(searchParams);
      return { ...emptyValue, ...defaults };
    },
  });
  const watchDay = watch("dt_r");
  const watchType = watch("g_r");
  const currentPage = searchParams.get("from");
  const currentdt_r = searchParams.get("dt_r");

  const gotoPage = (page) => {
    console.log("gotoPage", page);
    const params = new URLSearchParams(searchParams);
    if (page > 0) {
      params.set("from", page * 12);
    } else {
      params.delete("from");
    }
    setSearchParams(params);
  };

  useEffect(() => {
    const currPage = searchParams.get("from");
    const query = searchParams.get("t_r") || null;
    const genere = searchParams.get("g_r") || null;
    const giorno = searchParams.get("dt_r") || null;
    const luogo = searchParams.get("ml_r") || null;
    const relatore = searchParams.get("idrelatore_r") || null;
    const disciplina = searchParams.get("d_r") || null;
    const order = searchParams.get("order_r") || "titolo";
    let filter = { sezione_fe: "1" };
    if (genere) filter.genere = genere;
    if (giorno) filter.giorno = giorno;
    if (luogo) filter.luogo = luogo;
    if (relatore) filter.relatori = relatore;
    if (disciplina) filter.disciplina = disciplina;
    let sort = {};
    if (order === "data") {
      sort.date = "asc";
    } else {
      sort.title = "asc";
    }
    searchPF(query, filter, sort, currPage);
  }, [searchParams]);

  useEffect(() => {
    currentPage && resultSection.current.scrollIntoView();
  }, [currentPage]);

  useEffect(() => {
    if (
      watchType === ID_CONFERENZA ||
      watchType === ID_SPETTACOLO ||
      watchType === ID_EVENTO_SPECIALE
    ) {
      setValue("order_r", "data");
    } else {
      setValue("order_r", "titolo");
    }
  }, [watchType, setValue]);

  const dayRef = useRef(currentdt_r);
  useEffect(() => {
    if (watchDay && watchDay !== dayRef.current) {
      dayRef.current = watchDay;
      const params = new URLSearchParams(searchParams);
      params.delete("from");
      params.set("dt_r", watchDay);
      setSearchParams(params);
    }
  }, [watchDay, searchParams, setSearchParams]);

  const onSubmit = (variables) => {
    const params = new URLSearchParams();
    for (const key in variables) {
      variables[key] && params.set(key, variables[key]);
    }
    params.delete("from");
    setSearchParams(params);
  };

  const { data } = usePage(page.link.slice(1));

  return (
    <>
      <Seo title={page.name} />
      <Header data={breadcrumbs} />
      {breadcrumbs && <Breadcrumbs data={breadcrumbs} />}
      <div role="main" className="main-wrapper  mt-3" id="main">
        <div className="container-md">
          <div className="row">
            <div className="col-12">
              {pdf && (
                <a
                  className="scarica-programma external"
                  title="Scarica il programma"
                  href={pdf}
                >
                  <img
                    className="scarica-programma__img"
                    alt="Scarica il programma"
                    src={scaricaProgramma}
                  />
                </a>
              )}
              <h1>{data?.titoloInPagina || data?.titolo}</h1>
              <PageContent data={data} />
              {/* <p>
                <Link title="Programma scuole" to="/programma-scuole">
                  <strong>
                    Per il pubblico scolastico, oltre al programma in presenza,
                    è disponibile anche un programma di eventi online.
                    Consultalo&nbsp;qui.
                  </strong>
                </Link>
              </p> */}
            </div>
          </div>
        </div>
        <form name="search-event" onSubmit={handleSubmit(onSubmit)}>
          <div className="container-md">
            <div className="row">
              <div className="col-12">
                <Controller
                  control={control}
                  name="dt_r"
                  render={({ field: { onChange, value } }) => (
                    <CalendarSearch
                      data={FESTIVAL_DAY}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="search-event py-4 mt-4">
            <div className="container-md">
              <fieldset>
                <div className="row">
                  <div className="col-12 col-xl-10 offset-xl-1">
                    <div className="d-flex flex-column flex-lg-row justify-content-between gap-4">
                      <div className="d-flex flex-column flex-grow-1">
                        <div className="d-flex flex-column flex-lg-row justify-content-between gap-4">
                          <input {...register("dt_r")} type="hidden" />
                          <input {...register("order_r")} type="hidden" />
                          <Controller
                            name="g_r"
                            control={control}
                            render={({
                              field: { onChange, value, ...other },
                            }) => (
                              <CustomSelect
                                placeholder="Tipo Evento"
                                className="search-event__select"
                                options={TIPO_EVENTO}
                                value={
                                  TIPO_EVENTO.find(
                                    (type) => type.value === value
                                  ) || ""
                                }
                                onChange={(e) => {
                                  onChange(e?.value || "");
                                }}
                                isMulti={false}
                                {...other}
                              />
                            )}
                          />

                          {luoghi && (
                            <Controller
                              name="ml_r"
                              control={control}
                              render={({
                                field: { onChange, value, ...other },
                              }) => (
                                <CustomSelect
                                  placeholder="Luogo"
                                  className="search-event__select"
                                  options={luoghi}
                                  onChange={(e) => {
                                    onChange(e?.id || "");
                                  }}
                                  value={
                                    luoghi.find((type) => type.id === value) ||
                                    ""
                                  }
                                  isMulti={false}
                                  getOptionLabel={(option) =>
                                    option.descrizione
                                  }
                                  getOptionValue={(option) => option.id}
                                  {...other}
                                />
                              )}
                            />
                          )}
                        </div>

                        <div
                          className={classNames(
                            "search-event__advanced",
                            advanceSearch && "search-event__advanced--open"
                          )}
                        >
                          <div className="d-flex flex-column justify-content-between gap-2">
                            <div className="w-100 mt-4">
                              <input
                                className="search-event__input"
                                placeholder="Cerca nel titolo"
                                {...register("t_r", { required: false })}
                                type="text"
                              />
                            </div>
                            <div className="mt-4 d-flex flex-column flex-lg-row gap-4">
                              <Controller
                                name="d_r"
                                control={control}
                                render={({
                                  field: { onChange, value, ...other },
                                }) => (
                                  <CustomSelect
                                    placeholder="Disciplina"
                                    className="search-event__select"
                                    options={DISCIPLINE}
                                    onChange={(e) => {
                                      onChange(e?.value || "");
                                    }}
                                    value={
                                      DISCIPLINE.find(
                                        (type) => type.value === value
                                      ) || ""
                                    }
                                    isMulti={false}
                                    {...other}
                                  />
                                )}
                              />
                              {relatori && (
                                <Controller
                                  name="idrelatore_r"
                                  control={control}
                                  render={({
                                    field: { onChange, value, ...other },
                                  }) => (
                                    <CustomSelect
                                      placeholder="Protagonisti"
                                      className="search-event__select"
                                      options={relatori}
                                      onChange={(e) => {
                                        onChange(e?.idrelatore || "");
                                      }}
                                      isMulti={false}
                                      getOptionLabel={(option) =>
                                        `${option.nome} ${option.cognome}`
                                      }
                                      value={
                                        relatori.find(
                                          (type) => type.idrelatore === value
                                        ) || ""
                                      }
                                      getOptionValue={(option) =>
                                        option.idrelatore
                                      }
                                      {...other}
                                    />
                                  )}
                                />
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="w-100 text-end mt-4">
                          <button
                            className="search-event__btn me-4"
                            title="Azzera filtri"
                            type="reset"
                            onClick={() => {
                              reset(emptyValue);
                            }}
                          >
                            Azzera filtri
                            <Icon className="ms-2" name="xmark"></Icon>
                          </button>
                          <button
                            type="button"
                            className="search-event__btn"
                            title="Ricerca avanzata"
                            onClick={() => setAdvanceSearch(!advanceSearch)}
                          >
                            Ricerca avanzata
                            <Icon
                              className="ms-2"
                              name={advanceSearch ? "angle-up" : "angle-down"}
                            ></Icon>
                          </button>
                        </div>
                      </div>
                      <div>
                        <button className="search-event__submit" type="submit">
                          Cerca
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </form>
        <div ref={resultSection} />
        <div className="container-md">
          <div className="row">
            <div className="col-12">
              <div className="search-event__result-number my-4">
                {results?.nrows} risultati{" "}
                {results?.current > 1 && ` - pagina ${results.current}`}
              </div>
              <div className="row">
                {(results?.rows || placeholders(6)).map((item, index) => (
                  <div key={index} className="col-sm-6 col-lg-4">
                    <EventCard data={item} />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="search-event__pagination d-flex flex-wrap gap-2 justify-content-md-end">
                {results?.npages?.length > 1 &&
                  results?.npages?.map((item, index) => (
                    <button
                      className={classNames(
                        "",
                        results.current === index && "active"
                      )}
                      key={index}
                      onClick={() => gotoPage(index)}
                    >
                      {index + 1}
                    </button>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Programma;
