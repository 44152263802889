export const placeholders = (n = 4, key = "id") =>
  new Array(n).fill(0).map((_, i) => ({ [key]: i, placeholder: true }));

export const getRelatori = async () => {
  //const response = await fetch("/mock/relatori.json");
  const response = await fetch(`/relatori.json`);
  const data = await response.json();
  return data;
};

export const getProtagonisti = async (letter) => {
  const response = await fetch(`/relatori/relatori-${letter}.json`);
  const data = await response.json();
  return data;
};

export const getLuoghi = async () => {
  //const response = await fetch("/mock/luoghi.json");
  const response = await fetch("/locations.json");
  const data = await response.json();
  return data;
};

export const search = async (params, sezionefe = "1") => {
  //const response = await fetch("/mock/results.json");
  console.log(params?.size, params.toString());
  const qs = params.toString() || "order_r=titolo";
  const response = await fetch(`/api/search?sezione_fe=${sezionefe}&${qs}`);
  const data = await response.json();
  return data;
};

export const getEventSlugById = async (id) => {};

export const getEvent = async (slug) => {
  console.log("getEvent", slug);
  try {
    const response = await fetch(`/events/${slug}.json`);
    if (!response.ok) {
      throw new Error(`Error ${response.status} ${response.statusText}`);
    }
    const data = await response.json();
    return data;
  } catch (e) {
    return null;
  }
};
