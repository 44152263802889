import { createContext, useContext, useEffect, useMemo } from "react";
import { useNavigation } from "../hooks/useQueryCustom";

const NavigazioneContext = createContext({
  pages: [],
  topMenu: [],
  footer: [],
  mode: null,
  linkVideo: null,
  imgEdizione: null,
  bannerOrientamento: null,
  sponsor: false,
  programma: null,
  isLoading: false,
  isError: false,
  testata: null,
});

export const NavigazioneProvider = ({ children }) => {
  const { data, isLoading, isError } = useNavigation();
  //console.log("NavigazioneProvider", data);
  const contextValue = useMemo(() => {
    const topMenu = data ? data.topMenu : [];
    const pages = data ? data.pages : [];
    const footer = data?.footer;
    const linkVideo = data?.linkVideo;
    const mode = data?.mode || "Fuori Festival";
    const imgEdizione = data?.imgEdizione;
    const testata = data?.testata;
    const sponsor = data?.sponsor || false;
    const programma = data?.programma || null;
    const bannerOrientamento = data?.bannerOrientamento || null;
    const getPathByTemplate = (template) => {
      return getPageByTemplate(template)?.link || "#";
    };
    const getPageByTemplate = (template) => {
      return pages?.find((page) => page.template === template) || null;
    };
    const getLink = (item) => {
      if (item?.idEvento) {
        const programmaPage = getPathByTemplate("programma");
        return `${programmaPage}/id-${item.idEvento}/`;
      }
      if (item?.linkEsterno) return item.linkEsterno;
      if (item?.file?.url) return item.file.url;

      return item.__typename === "Articolo"
        ? `/news/${item.slug}/`
        : `/${item.slug}`;
    };

    return {
      pages,
      mode,
      topMenu,
      footer,
      linkVideo,
      sponsor,
      programma,
      bannerOrientamento,
      imgEdizione,
      isLoading,
      testata,
      isError,
      getLink,
      getPathByTemplate,
      getPageByTemplate,
    };
  }, [data, isLoading, isError]);

  useEffect(() => {
    if (data?.mainColor) {
      //console.log("set main color");
      document.documentElement.style.setProperty("--fds-color", data.mainColor);
    }
  }, [data]);

  return (
    <NavigazioneContext.Provider value={contextValue}>
      {children}
    </NavigazioneContext.Provider>
  );
};

export const useNavigazione = () => {
  return useContext(NavigazioneContext);
};

export default NavigazioneContext;
