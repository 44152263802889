import { filterPageByMode } from "./utility";

const OVERWRITE_MODE = import.meta.env.VITE_OVERWRITE_MODE;
const ASSETS_URL_REGEX = /^https:\/\/(images|assets)\.ctfassets\.net\//;

const parseAndReplaceAssetUrls = (jsonString) =>
  JSON.parse(jsonString, (key, value) =>
    key === "url" && ASSETS_URL_REGEX.test(value)
      ? "/cms/".concat(
          value.substring(value.lastIndexOf("/") + 1).split("?")[0]
        )
      : value
  );

const fetchCms = async (path) => {
  const response = await fetch(`/mock/${path}`);
  const jsonString = await response.text();
  const { data, _errors } = parseAndReplaceAssetUrls(jsonString);
  if (!response.ok) {
    throw new Error(`Fetch error ${response.status} ${response.statusText}`);
  }
  console.log(data);
  return data;
};

const TEMPLATES = {
  Home: "home",
  Programma: "programma",
  "Edizioni precedenti": "archivio",
  "Programma scuole": "programmaScuole",
  Protagonisti: "protagonisti",
  "Area clienti": "areaClienti",
  "Biglietti e Prenotazioni": "bigliettiPrenotazioni",
  "Iscrizione newsletter": "newsletter",
  Sponsor: "sponsor",
  "Scrivi al Festival": "scriviFestival",
  "Early bird": "earlyBird",
  "Acquista biglietti": "biglietti",
  "Inserisci Premium": "premium",
  "Completa acquisto": "carrello",
  "Elenco acquisti": "ordini",
  Extra: "programmaExtra",
};

const pageFromData = (data, allPages) => {
  const page = {
    name: data.titolo,
    link: `/${data.slug !== "/" ? data.slug : ""}`,
    template: data.layout ? TEMPLATES[data.layout] || "generic" : null,
    label: data?.label,
  };
  const parent = allPages.find((p) => {
    return p.pagineFiglieCollection.items.find((f) => f.slug === data.slug);
  });

  if (parent) {
    page.parent = {
      name: parent.titolo,
      link: `/${parent.slug}`,
    };
  }
  /*  if (data.linkedFrom?.paginaCollection?.items?.length) {
    const parentPage = data.linkedFrom.paginaCollection.items[0];
    page.parent = {
      name: parentPage.titolo,
      link: `/${parentPage.slug}`,
    }; 
  }*/
  return {
    ...page,
    pages: data.pagineFiglieCollection?.items?.length
      ? data.pagineFiglieCollection?.items.map((sub) => ({
          name: sub.titolo,
          link: `/${sub.slug}`,
          template: sub.layout ? TEMPLATES[sub.layout] || "generic" : null,
          parent: page,
        }))
      : null,
  };
};

export const pageFromFields = (fields) => {
  const page = {
    name: fields.titolo,
    link: `/${fields.slug !== "/" ? fields.slug : ""}`,
    template: TEMPLATES[fields.layout] || "generic",
  };
  return {
    ...page,
    pages: fields.pagineFiglie?.map((sub) => ({
      name: sub.fields.titolo,
      link: `/${sub.fields.slug}`,
      template: TEMPLATES[fields.layout] || "generic",
      parent: page,
    })),
  };
};

export const getNavigation = async (isAdmin) => {
  const { navigazioneCollection, paginaCollection } = await fetchCms(
    "navigazione.json"
  );
  const allPages = paginaCollection.items;

  const mode =
    OVERWRITE_MODE || navigazioneCollection?.items?.[0]?.modalitFestival;
  const menuPages =
    filterPageByMode(
      navigazioneCollection?.items?.[0]?.topMenuCollection?.items,
      mode
    ) || [];
  const linkVideo = navigazioneCollection?.items?.[0]?.linkVideo;
  const imgEdizione =
    navigazioneCollection?.items?.[0]?.immagineTestataEdizione?.url.replace(
      ASSETS_URL_REGEX,
      "/"
    );
  const testata =
    navigazioneCollection?.items?.[0]?.testataEdizione?.url.replace(
      ASSETS_URL_REGEX,
      "/"
    );
  const programma = navigazioneCollection?.items?.[0]?.programma?.url.replace(
    ASSETS_URL_REGEX,
    "/"
  );
  const bannerOrientamento =
    navigazioneCollection?.items?.[0]?.bannerOrientamento?.url.replace(
      ASSETS_URL_REGEX,
      "/"
    );
  const altrePagine = isAdmin
    ? paginaCollection?.items
    : filterPageByMode(paginaCollection?.items, mode) || [];
  const footer =
    navigazioneCollection?.items?.[0]?.footerCollection?.items || [];

  const sponsor = navigazioneCollection?.items?.[0]?.sponsor;
  const mainColor = navigazioneCollection?.items?.[0]?.mainColor;

  const pages = altrePagine.map((item) => pageFromData(item, allPages));

  const topMenu = [
    {
      name: "Home",
      link: "/",
      template: "home",
    },
  ].concat(menuPages.map((item) => pageFromData(item, allPages)));

  return {
    pages,
    topMenu,
    footer,
    sponsor,
    mode,
    linkVideo,
    testata,
    imgEdizione,
    programma,
    bannerOrientamento,
    mainColor,
  };
};

export const getNews = async () => {
  const response = await fetch("/mock/news.json");
  const data = response.json();
  return data;
};

export const getEdizioni = async () => {
  const { edizionePrecedenteCollection } = await fetchCms("edizioni.json");
  return edizionePrecedenteCollection?.items;
};

export const getHome = async () => {
  const data = await fetchCms("home.json");
  return data.paginaCollection?.items?.[0] || [null];
};

export const getPage = async (slug) => {
  const { paginaCollection } = await fetchCms(`${slug}.json`);
  return paginaCollection?.items?.[0] || null;
};

export const getArticle = async (slug) => {
  const { articoloCollection } = await fetchCms(`${slug}.json`);
  return articoloCollection?.items?.[0] || null;
};

export const getSponsor = async () => {
  const { paginaCollection } = await fetchCms("sponsor.json");
  return paginaCollection?.items?.[0] || null;
};

export const getFasciaArticoli = async (id) => {
  const { fasciaArticoli } = await fetchCms(`fascia-${id}.json`);

  return fasciaArticoli || null;
};
