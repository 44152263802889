import { useMemo, Suspense, lazy } from "react";
import { useParams, Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Header from "../components/header";
import Breadcrumbs from "../components/breadcrumbs";
import { useEvent } from "../hooks/useQueryCustom";
import Icon from "../components/icons";
import Seo from "../components/seo";
import {
  getTarget,
  getDisciplina,
  onlyOnline,
  getTipologiaScuole,
  isScuole,
  isExtra,
  isConferenza,
  isSpettacolo,
  isPrenotabile,
  whoTab,
  getURLImage,
  getAuthor,
  getSubType,
  decodeValue,
  getMarkerLocation,
  isPrenotabileOnline,
} from "../utils/utility";
import { useNavigazione } from "../context/navigazione";
import classNames from "classnames";

const Mappa = lazy(() => import("../components/event-map"));

const Event = () => {
  const { slug } = useParams();
  const { data, isFetched } = useEvent(slug);
  const { getPageByTemplate } = useNavigazione();

  const utils = useMemo(() => {
    return {
      scuole: isScuole(data),
      extra: isExtra(data),
      conferenza: isConferenza(data),
      spettacolo: isSpettacolo(data),
      isOnlyOnline: onlyOnline(data),
      target: getTarget(data),
      who: whoTab(data),
      subtype: getSubType(data),
      curatori: decodeValue(data, "curatori"),
      collaboratori: decodeValue(data, "collaboratori"),
      markerLocation: getMarkerLocation(data),
      prenotabile: isPrenotabile(data),
      prenotabileOnline: isPrenotabileOnline(data),
    };
  }, [data]);
  const programmaPage = getPageByTemplate("programma");

  //console.log("programmaPage", programmaPage);
  const pageData = utils.extra
    ? getPageByTemplate("programmaExtra")
    : programmaPage;

  const dataBreadcrumb = data
    ? [
        {
          label: "Home",
          link: "/",
        },
        utils.extra && {
          label: programmaPage.name,
          link: programmaPage.link,
        },
        {
          label: pageData.name,
          link: pageData.link,
        },
        {
          label: data.titolo,
        },
      ].filter(Boolean)
    : [];

  return (
    <>
      {data && <Seo title={data.titolo} />}
      <Header data={dataBreadcrumb} />
      <Breadcrumbs data={dataBreadcrumb} />
      <div role="main" className="main-wrapper  mt-3" id="main">
        {isFetched ? (
          data ? (
            <div className="container">
              <div className="row">
                <div className="col-md-8">
                  <div
                    className={classNames(
                      data.sezione_fe === "3" &&
                        "d-flex justify-content-between gap-2"
                    )}
                  >
                    <h1>{data.titolo}</h1>
                    {data.sezione_fe === "3" && (
                      <div className="event__extra align-self-start">Extra</div>
                    )}
                  </div>

                  <h2>{data.sottotitolo}</h2>
                  <div className="event__author">{getAuthor(data)}</div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <h3 className="graphic">
                        <span className="graphic__label">Quando</span>
                        <span className="graphic__line">&nbsp;</span>
                      </h3>
                      <p className="event__orario">
                        {data?.eventi_organizzazione.orario}
                      </p>
                    </div>
                    {utils.scuole ? (
                      <div className="col-md-6 mb-3">
                        <h3 className="graphic">
                          <span className="graphic__label">Tipologia</span>
                          <span className="graphic__line">&nbsp;</span>
                        </h3>
                        <p>
                          Programma scuole online
                          <br />
                          {getTipologiaScuole(data)}
                        </p>
                      </div>
                    ) : (
                      <div className="col-md-6 mb-3">
                        <h3 className="graphic">
                          <span className="graphic__label">Dove</span>
                          <span className="graphic__line">&nbsp;</span>
                        </h3>
                        <p>
                          {data?.eventi_organizzazione.luogo.descrizione}
                          <br />
                          {!onlyOnline(data) && (
                            <>{data?.eventi_organizzazione.luogo.indirizzo}</>
                          )}
                          {data?.eventi_organizzazione?.luogo_descrizione && (
                            <p>
                              {data?.eventi_organizzazione.luogo_descrizione}
                            </p>
                          )}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <h3 className="graphic">
                        <span className="graphic__label">Età consigliata</span>
                        <span className="graphic__line">&nbsp;</span>
                      </h3>
                      <p>{utils.target}</p>
                    </div>
                    <div className="col-md-6 mb-3">
                      <h3 className="graphic">
                        <span className="graphic__label">
                          {utils.scuole
                            ? "Discilplina"
                            : "Tipologia e disciplina"}
                        </span>
                        <span className="graphic__line">&nbsp;</span>
                      </h3>
                      <p>
                        {!utils.scuole && (
                          <>
                            {utils.subtype}
                            <br />
                          </>
                        )}
                        {getDisciplina(data)}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="event-tabs">
                        <Tabs>
                          <TabList>
                            <Tab>Cosa</Tab>
                            {utils.who && <Tab>Chi</Tab>}
                            {!utils.scuole && !utils.isOnlyOnline && (
                              <Tab>Dove</Tab>
                            )}
                          </TabList>

                          <TabPanel>
                            <img
                              className="float-start me-3 mb-2"
                              src={getURLImage(data)}
                              alt=""
                            />
                            <div
                              dangerouslySetInnerHTML={{
                                __html: data.descrizione,
                              }}
                            ></div>

                            {data.note && (
                              <div className="mt-3">
                                <h3 className="graphic">
                                  <span className="graphic__label graphic__label--gray-light">
                                    Note
                                  </span>
                                  <span className="graphic__line">&#160;</span>
                                </h3>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: data.note,
                                  }}
                                  className="tabs__note"
                                />
                              </div>
                            )}
                            {utils.curatori && (
                              <div className="mt-3">
                                <h3 className="graphic">
                                  <span className="graphic__label graphic__label--gray-light">
                                    A cura di
                                  </span>
                                  <span className="graphic__line">&#160;</span>
                                </h3>
                                <div className="tabs__note">
                                  {utils.curatori}
                                </div>
                              </div>
                            )}
                            {utils.collaboratori && (
                              <div className="mt-3">
                                <h3 className="graphic">
                                  <span className="graphic__label graphic__label--gray-light">
                                    In collaborazione con
                                  </span>
                                  <span className="graphic__line">&#160;</span>
                                </h3>
                                <div className="tabs__note">
                                  {utils.collaboratori}
                                </div>
                              </div>
                            )}
                            {data.sponsor && (
                              <div className="mt-3">
                                <h3 className="graphic">
                                  <span className="graphic__label graphic__label--gray-light">
                                    Con il supporto di
                                  </span>
                                  <span className="graphic__line">&#160;</span>
                                </h3>
                                <p>{data.sponsor}</p>
                              </div>
                            )}
                          </TabPanel>
                          {utils.who && (
                            <TabPanel>
                              {data.relatori.map((item) => (
                                <div
                                  key={item.idrelatore}
                                  className="mb-3"
                                  dangerouslySetInnerHTML={{
                                    __html: item.biografia,
                                  }}
                                ></div>
                              ))}
                            </TabPanel>
                          )}
                          {!utils.scuole && !utils.isOnlyOnline && (
                            <TabPanel>
                              <p>
                                {data?.eventi_organizzazione.luogo.descrizione}
                                <br />
                                {data?.eventi_organizzazione.luogo.indirizzo}
                              </p>
                              {utils.markerLocation && (
                                <Suspense
                                  fallback={
                                    <Icon name="spinner icon--animated" />
                                  }
                                >
                                  <Mappa
                                    markerLocation={utils.markerLocation}
                                  />
                                </Suspense>
                              )}
                            </TabPanel>
                          )}
                        </Tabs>
                      </div>
                    </div>
                  </div>
                  {data.loghi?.length > 0 && (
                    <div className="row">
                      <div className="col-12">
                        <div className="d-flex gap-3 my-3">
                          {data.loghi.map((item) => (
                            <div key={item.nome}>
                              <a href={item.link} title={item.nome}>
                                <img
                                  src={item.url?.replace(
                                    "/logo_imm/",
                                    "/logo_imm/2023/"
                                  )}
                                  alt={item.nome}
                                  title={item.nome}
                                />
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-md-4">
                  {data.eventi_organizzazione.fruizione_online_info && (
                    <div className="text-break">
                      <h2>Per seguire l'evento online</h2>
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            data.eventi_organizzazione.fruizione_online_info,
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h1>Evento non trovato</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Link to="/">Torna alla Homepage</Link>
                </div>
              </div>
            </div>
          )
        ) : (
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="text-center my-5">
                  <span
                    className="spinner-border spinner-border-lg"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Event;
