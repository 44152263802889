import { useFasciaArticoli } from "../hooks/useQueryCustom";
import Accordion from "./accordion";
import ContentfulRichText from "./contentful-rich-text";
import News from "./news";
import SectionLink from "./section-link";

const FasciaArticoli = ({ id, rientro = false }) => {
  const { data } = useFasciaArticoli(id);
  // console.log("FasciaArticoli: ", data);
  return (
    <>
      {data?.layout === "Accordion" &&
        data?.articoliCollection?.items?.map((item, index) => (
          <Accordion
            key={index}
            title={item?.titoloInPagina || item.titolo}
            rientro={data.rientro || rientro}
          >
            <ContentfulRichText data={item.testo} />
          </Accordion>
        ))}
      {data?.layout === "Card" && <News data={data} />}
      {data?.layout === "Link due righe" && (
        <SectionLink data={data} small={data.rientro || rientro} />
      )}
    </>
  );
};

export default FasciaArticoli;
