export const ID_CONFERENZA = "2";
export const ID_SPETTACOLO = "3";
export const ID_EVENTO_SPECIALE = "4";
export const ID_MOSTRA = "5";
export const ID_LABORATORIO = "6";
export const ID_EVENTO_OSPITE = "7";
export const FESTIVAL_YEAR = "2023";
export const NOFESTIVAL_MODE = "Fuori Festival";
export const ONLYEDITION_MODE = "Solo testata";
export const EARLYBIRD_MODE = "Early bird";
export const ECOMMERCE_MODE = "Ecommerce";
export const ARCHIVE_MODE = "Archivio";
export const MAX_TICKET = 9;

const URL_BO = import.meta.env.VITE_BO_ADMIN;
export const URL_PDF_ACQUISTO = `${URL_BO}/fadmin/ecommerce/acquisto_web_pdf.php`;
export const URL_PDF_AMICO = `${URL_BO}/pdf/riepilogoAF_web_pdf.php`;
export const URL_PDF_SERVICE = `${URL_BO}/pdf/topdf.php`;

export const FESTIVAL_DAY = {
  ottobre: [
    {
      daycode: "20231026",
      searchcode: "2610",
    },
    {
      daycode: "20231027",
      searchcode: "2710",
    },
    {
      daycode: "20231028",
      searchcode: "2810",
    },
    {
      daycode: "20231029",
      searchcode: "2910",
    },
    {
      daycode: "20231030",
      searchcode: "3010",
    },
    {
      daycode: "20231031",
      searchcode: "3110",
    },
  ],
  novembre: [
    {
      daycode: "20231101",
      searchcode: "0111",
    },
    {
      daycode: "20231102",
      searchcode: "0211",
    },
    {
      daycode: "20231103",
      searchcode: "0311",
    },
    {
      daycode: "20231104",
      searchcode: "0411",
    },
    {
      daycode: "20231105",
      searchcode: "0511",
    },
  ],
};

export const FESTIVAL_DAY_SCUOLE = {
  ottobre: [
    {
      daycode: "20231020",
      searchcode: "2010",
    },
    {
      daycode: "20231021",
      searchcode: "2110",
    },
    {
      daycode: "20231024",
      searchcode: "2410",
    },
    {
      daycode: "20231025",
      searchcode: "2510",
    },
    {
      daycode: "20231026",
      searchcode: "2610",
    },
    {
      daycode: "20231027",
      searchcode: "2710",
    },
    {
      daycode: "20231028",
      searchcode: "2810",
    },
  ],
};

export const DISCIPLINE = [
  {
    value: "6",
    label: "Astrofisica, cosmologia e spazio",
  },
  {
    value: "3",
    label: "Chimica e materiali",
  },
  {
    value: "5",
    label: "Energia, tecnologia, ICT e industria",
  },
  {
    value: "4",
    label: "Fisica e materia",
  },
  {
    value: "8",
    label: "Matematica, Logica e Informatica",
  },
  {
    value: "7",
    label: "Medicina e Scienze Biomediche",
  },
  {
    value: "10",
    label: "Scienza e società",
  },
  {
    value: "1",
    label: "Scienze della Terra, del Mare e dell'Ambiente",
  },
  {
    value: "2",
    label: "Scienze della Vita",
  },
  {
    value: "9",
    label: "Scienze umane, arte e filosofia",
  },
];

export const TIPO_EVENTO = [
  {
    value: ID_CONFERENZA,
    label: "Conferenza",
  },
  {
    value: ID_EVENTO_SPECIALE,
    label: "Evento speciale",
  },
  {
    value: ID_LABORATORIO,
    label: "Laboratorio",
  },
  {
    value: ID_MOSTRA,
    label: "Mostra",
  },
  {
    value: ID_SPETTACOLO,
    label: "Spettacolo",
  },
];

export const target = [
  {
    value: "2",
    label: "4-5 anni",
  },
  {
    value: "3",
    label: "6-7 anni",
  },
  {
    value: "4",
    label: "8-10 anni",
  },
  {
    value: "5",
    label: "11-13 anni",
  },
  {
    value: "6",
    label: "14-15 anni",
  },
  {
    value: "7",
    label: "16-19 anni",
  },
  {
    value: "8",
    label: ">20 anni e oltre",
  },
];

export const TIPO_CONFERENZA = [
  {
    id: "1",
    label: "Lectio Magistralis",
  },
  {
    id: "2",
    label: "Dialogo internazionale",
  },
  {
    id: "3",
    label: "Caffè scientifico",
  },
  {
    id: "4",
    label: "Conversazione",
  },
  {
    id: "5",
    label: "Dialogo",
  },
  {
    id: "6",
    label: "Conferenza/Spettacolo",
  },
  {
    id: "7",
    label: "Evento Speciale",
  },
  {
    id: "8",
    label: "Incontro",
  },
  {
    id: "9",
    label: "Convegno",
  },
  {
    id: "10",
    label: "Seminario",
  },
  {
    id: "11",
    label: "Tavola rotonda",
  },
  {
    id: "13",
    label: "Documentario/Film",
  },
  {
    id: "15",
    label: "Webinar",
    alias: "Digitalk",
  },
  {
    id: "16",
    label: "Webinar",
  },
];

export const TIPO_SPETTACOLO = [
  {
    id: "1",
    label: "Spettacolo teatrale",
  },
  {
    id: "2",
    label: "Documentario",
  },
  {
    id: "3",
    label: "Spettacolo musicale",
  },
  {
    id: "4",
    label: "Teatro di narrazione scientifica",
  },
  {
    id: "5",
    label: "Concerto",
  },
  {
    id: "6",
    label: "Film",
  },
  {
    id: "7",
    label: "Monologo",
  },
  {
    id: "8",
    label: "Teatro per ragazzi",
  },
];

export const TIPO_MOSTRA = [
  {
    id: "1",
    label: "Mostra fotografica",
  },
  {
    id: "2",
    label: "Mostra interattiva",
  },
  {
    id: "3",
    label: "Mostra illustrativa con pannelli",
  },
  {
    id: "4",
    label: "Mostra multimediale",
  },
  {
    id: "5",
    label: "Installazione",
  },
];

export const TIPO_LABORATORIO = [
  {
    id: "1",
    label: "In presenza",
  },
  {
    id: "2",
    label: "Digilab",
  },
];

export const ERRORS = [
  {
    code: "E3",
    message: {
      text: "E' possibile acquistare un solo biglietto premium",
      variant: "warning",
    },
  },
  {
    code: "E4",
    message: {
      text: "Non si possono acquistare Abbonamenti Premium e Abbonamenti Standard con lo stesso account. Per acquistare altri titoli è necessario usare un account diverso. ",
      variant: "warning",
    },
  },
  {
    code: "E5",
    message: {
      text: "Non si possono acquistare più di 40 biglietti",
      variant: "warning",
    },
  },
  {
    code: "E7",
    message: {
      text: "Per acquistare un premium il carrello deve essere vuoto.",
      variant: "warning",
    },
  },
];

export const PLANET_EARLYBIRD = "346";

export const PLANET_A = [
  { id: "20231026", evplanet: "336" },
  { id: "20231027", evplanet: "337" },
  { id: "20231028", evplanet: "338" },
  { id: "20231029", evplanet: "339" },
  { id: "20231030", evplanet: "340" },
  { id: "20231031", evplanet: "341" },
  { id: "20231101", evplanet: "342" },
  { id: "20231102", evplanet: "343" },
  { id: "20231103", evplanet: "344" },
  { id: "20231104", evplanet: "345" },
];

export const PLANET_B = [
  { id: "2610", evplanet: "39540" },
  { id: "2710", evplanet: "39541" },
  { id: "2810", evplanet: "39542" },
  { id: "2910", evplanet: "39543" },
  { id: "3010", evplanet: "39544" },
  { id: "3110", evplanet: "39545" },
  { id: "0111", evplanet: "39546" },
  { id: "0211", evplanet: "39547" },
  { id: "0311", evplanet: "39548" },
  { id: "0411", evplanet: "39549" },
  { id: "0511", evplanet: "39550" },
];

export const PAGE_MODE_FILTER = [
  {
    mode: NOFESTIVAL_MODE,
    layout: [
      "Programma",
      "Biglietti e prenotazioni",
      "Area clienti",
      "Programma scuole",
      "Early bird",
      "Acquista biglietti",
      "Completa acquisto",
    ],
  },
  {
    mode: ARCHIVE_MODE,
    layout: [
      "Biglietti e prenotazioni",
      "Area clienti",
      "Programma scuole",
      "Early bird",
      "Acquista biglietti",
      "Completa acquisto",
    ],
  },
  {
    mode: ONLYEDITION_MODE,
    layout: [
      "Programma",
      "Biglietti e prenotazioni",
      "Area clienti",
      "Programma scuole",
      "Early bird",
      "Acquista biglietti",
      "Completa acquisto",
    ],
  },
  {
    mode: EARLYBIRD_MODE,
    layout: ["Programma", "Programma scuole", "Acquista biglietti"],
  },
  {
    mode: ECOMMERCE_MODE,
    layout: ["Early bird"],
  },
];
