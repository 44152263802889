import Icon from "../components/icons";

const SocialBar = () => {
  return (
    <div className="social-bar d-none d-md-block">
      <a
        className="social-bar__link social-bar__link--first"
        title="Facebook"
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.facebook.com/festivalscienza"
      >
        <Icon name="facebook" />
      </a>

      <a
        className="social-bar__link"
        title="Twitter"
        target="_blank"
        rel="noopener noreferrer"
        href="https://twitter.com/fdellascienza?lang=it"
      >
        <Icon name="twitter" />
      </a>

      <a
        className="social-bar__link"
        title="YouTube"
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.youtube.com/user/FestivalScienza"
      >
        <Icon name="youtube" />
      </a>

      <a
        className="social-bar__link"
        title="Instagram"
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.instagram.com/festivalscienza/"
      >
        <Icon name="instagram" />
      </a>

      <a
        className="social-bar__link"
        title="Flickr"
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.flickr.com/photos/festivaldellascienza/"
      >
        <Icon name="flickr" />
      </a>
    </div>
  );
};

export default SocialBar;
